import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: '',
    /* 公共导航栏参数 */
    navigationBar: {
      name: '', //导航中显示的页面名字
      showBar: true //是否显示导航栏
    }
  },
  mutations: {
    changeUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    changeUserInfoToupiao(state, status) {
      state.userInfo.toupiao = status
    },
    // 修改公共导航栏参数
    changeNavigationBar(state, data) {
      state.navigationBar = data
    }
  },
  getters: {
    getNavigationBarStatus(state) {
      return state.navigationBar
    }
  },
  actions: {},
  modules: {},
})
