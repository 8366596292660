import axios from "../util/request"

export default {
	// 获取用户信息
	toupiaoInit(id) {
		return axios.post('index/toupiao2/toupiaoInit',{id})
	},
	// 获取理事单位的投票列表
	getCollageList() {
		return axios.get('index/toupiao2/lishidanwei')
	},
	getChangwulishiList() {
		return axios.get('index/toupiao2/changwulishi')
	},
	getBanziList() {
		return axios.get('index/toupiao2/banzi')
	},
	touPiao(id,data){
		console.log(data.length)
		data = JSON.stringify(data)
		return axios.post('index/toupiao2/save',{id,data})
	},
	qiandao(){
		return axios.get('index/qiandao/qiandao')
	}
}
