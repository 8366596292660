<template>
  <div>
    <keep-alive :include="include">
      <router-view></router-view>
    </keep-alive>

<!--     <p id="footer">联系我们：<a href="mailto:gz13411851062@163.com">gz13411851062@163.com（点击启动邮箱）</a>
       <br/>Copyright©中国教育会计学会高职分会&nbsp;2020-{{ new Date().getFullYear() }} <br>自动构建时间：{{ BUILDTEIME }}<br>技术支持：萌点云科技（深圳）有限公司<br>技术支持电话：075533122297</p>-->
    </div>
    <!-- <StuNavBar></StuNavBar> -->
</template>

<script>
import StuNavBar from "../components/StuNavBar2";
import api from "@/api";
import { setToken } from "@/util/auth";

export default {
  name: "home",
  mounted() {
    this.BUILDTEIME = process.env.VUE_APP_BUILDTEIME;
  },
  components: {
    StuNavBar,
  },
  data() {
    return {
      include: ["User", "Home", "Index"],
      BUILDTEIME: "",
    };
  },
  methods: {},
};
</script>
<style scoped>
#footer {
  font-size: 20px;
  text-align: center;
  margin: 30px 0 10px 0;
  background-color: #ecedee;
  //letter-spacing: 1px;
  line-height: 30px;
}
</style>
