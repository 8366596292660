<template>
    <md-dialog
            :title="dialogData.title"
            v-model="isOpen"
            :btns="dialogData.btns"
    >
        <div class="dialog-banner" slot="header" v-if="banner.length !=0">
            <img :src="banner"
                 :alt="dialogData.title">
        </div>
        {{msg}}
    </md-dialog>

</template>

<script>
    export default {
        name: "WechatDialog",
        props: {
            banner: {
                type: String,
                defalut: '/wechat/fuwuhao8cm.png'
            },
            msg:{
                type: String,
                defalut: ''
            }
        },
        methods: {
            openIt() {
                this.isOpen = true
            }
        },
        data() {
            return {
                isOpen: false,
                dialogData: {
                    // msg: '长按二维码识别',
                    title: '长按二维码识别',
                    // banner:require('@/assets/guanggao.png'),
                    btns: [
                        {
                            text: '关闭',
                            handler: this.isOpen = false,
                        },
                    ],
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .dialog-banner
        img
            display block
            width 100%
</style>
