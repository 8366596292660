<template>
  <div>
    <my-file ref="form"></my-file>
    <div class="md-example-section">
      <md-action-bar :actions="data"></md-action-bar>
      <!--
      <md-button type="primary" inline plain @click="goback">返回</md-button>
      <md-button type="primary" inline @click="submmit">提交</md-button> -->
    </div>
  </div>
</template>

<script>
import MyFile from "../components/MyFile1";
import {
  InputItem,
  Field,
  Radio,
  FieldItem,
  TextareaItem,
  ActionBar,
  Toast,
} from "mand-mobile";
export default {
  components: {
    MyFile,
    [InputItem.name]: InputItem,
    [Field.name]: Field,
    [Radio.name]: Radio,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [Radio.name]: Radio,
    [TextareaItem.name]: TextareaItem,
    [ActionBar.name]: ActionBar,
  },
  name: "UserEnroll",
  data() {
    return {
      sex: "1",
      value: "",
      data: [
        {
          text: "返回",
          onClick: this.goback,
        },
        {
          text: "提交",
          onClick: this.submmit,
        },
      ],
    };
  },
  methods: {
    format(name, curValue, curPos) {
      return {
        value: curValue.replace(/\d/g, ""),
        range: curPos,
      };
    },

    goback() {
      this.$router.go(-1);
    },
    submmit() {
      this.$refs.form.submmit_info();
    },
    handleClick() {
      Toast.succeed("Click");
    },
  },
};
</script>

<style lang="stylus" scoped>
span {
  font-size: 34px;
}

.add {
  float: right;
}

.md-field {
  margin-top: 100px;
}

.demo2-footer {
  display: flex;
  justify-content: space-between;
  color: #999;
  font-size: 24px;
}

.md-example-section {
  display: flex;
  margin-top: 200px;

  .md-button {
    width: 300px;
    margin-left: 50px;
  }
}
</style>
