<template>
  <div>
    <div class="topMenu"></div>
    <div class="header">
      <!--          上方-->
      <div class="headerContent">
        <img class="avater" :src="avater"/>
        <div class="userMsg">
         <div class="unitNumber">
           <p>{{userInfo.collage || '未知'}}</p>
           <p class="number">{{userInfo.collage_id || 0}}</p>
         </div>
         <div class="name">
          {{userInfo.name || '未知'}}
         </div>
         <div class="tag">{{userInfo.zhiwei || '未知'}}</div>
        </div>
      </div>

    </div>
    <hr style='background-color:#EAECEF; height:1px; border:none'>
    <div class="menus">
      <md-field>
        <md-cell-item :title="item.title" arrow :no-border="true" v-for="(item,index) in menus" :key="index" @click="goTo(item.url)">
          <img class="holder" slot="left" :src="item.iconSrc"/>
        </md-cell-item>
      </md-field>
      <md-field class="menus2">
        <md-cell-item :title="item.title" arrow :no-border="true" v-for="(item,index) in menus2" :key="index" @click="goTo(item.url)">
          <img class="holder" slot="left" :src="item.iconSrc"/>
        </md-cell-item>
      </md-field>
      <!--        <下方菜单></下方菜单>-->
    </div>
  </div>
</template>

<script>
import commandList from "../components/User/commandList";
import taskList from "../components/User/taskList";
import {Icon, Field, CellItem, Tag, Dialog} from 'mand-mobile'
import api from "@/api";

export default {
  name: "User",
  components: {
    [Icon.name]: Icon,
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [Tag.name]: Tag,
  },
  data() {
    return {
      commandList,
      taskList,
      avater: require('@/assets/logo.png'),
      nickName: '高职分会',
      banner: '/wechat/fuwuhao8cm.png',
      menus:[
        {
          title:'个人信息',
          iconSrc:require('@/assets/icon/menus/1.png'),
          url:'/collageinfo/user'
        },
        {
          title:'单位信息',
          iconSrc:require('@/assets/icon/menus/2.png'),
          url:'/collageinfo/unit'
        },
        {
          title:'会员列表',
          iconSrc:require('@/assets/icon/menus/3.png'),
          url:'/collageList'
        },
        {
          title:'协作组',
          iconSrc:require('@/assets/icon/menus/4.png'),
          url: 'cooperativeGroup'
        }
      ],
      menus2: [
        {
          title:'常见问题',
          iconSrc:require('@/assets/icon/menus/problem.png'),
        },
        {
          title:'安全中心',
          iconSrc:require('@/assets/icon/menus/safety.png'),
        },
        {
          title:'技术支持',
          iconSrc:require('@/assets/icon/menus/support.png'),
        },
        {
          title:'关于我们',
          iconSrc:require('@/assets/icon/menus/about.png'),
        },
      ]
    }
  },
  mounted() {
    this.getUserInfo()
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    goTo(url){
      this.$router.push(url)
    },
    async getUserInfo(){
      let res2 = await api.user.getUserInfo()
      console.log("getUserInfo res2:", res2)
      if (res2.errno === 0) {
        this.$store.commit("changeUserInfo", res2.data)
      } else {
        // 若登录失败，提示失败信息
        Dialog.alert({ content: res2.msg })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
hr
 margin 0
 color #2c6cf5
.topMenu
  //height 80px
  background-color #2A78DC

.header
  //background-color #0d060e
  .headerContent
    padding 20px 30px 50px 20px
    display flex
    height 200px
    //align-items center
  .avater
    height 200px
    width 200px
    border-radius 50%
  .userMsg
    display flex
    flex-direction column
    justify-content space-around
    margin-left 40px
    .unitNumber
      font-size 38px
      font-weight 600
      font-family "Avenir-Black"
      color #1E2432
      .number
        margin-right 30px
    .name
      font-size 30px
      font-weight 600
      font-family "Avenir-Black"
      color #1E2432
    .tag
      display flex
      justify-content center
      align-items center
      width 200px
      height 45px
      background-color rgba(255, 45, 85, 1)
      border-radius 10px
      font-size 20px
      color white
    .level
       margin 0px 20px
    // .nickName
    //   font-size 70px
    //   font-family "Avenir-Black";
    //   font-weight: 400;
    //   margin-bottom 20px
.menus
  margin 20px 40px
  min-height 50vh
  .menus2
    margin-top 50px
  /deep/ .md-field
    padding 0
    width 100%
  /deep/ .md-cell-item-body
    padding  0
  .holder
    display block
    width 60px
    height 60px
    border-radius 6px
    //background-color #E6E6E6
</style>
