<template>
  <div class="toulishidanwei">
    <div class="title"><span>投票选举理事单位</span> 的选举</div>
    <md-check-list v-model="options" iconPosition="left" :options="option" />
    <btn class="btn" @all="allChoose" :isAll="isAll" />
  </div>
</template>

<script>
import { Field, CheckList } from 'mand-mobile'
import btn from '../components/touPiao/button'
export default {
  components: { [Field.name]: Field, [CheckList.name]: CheckList, btn },
  name: 'Toulishidanwei',
  data () {
    return {
      options: ['lisi', 'zhangsan'],
      isAll: false,
      option: [
        { value: 'zhangsan', label: '张三', brief: '深圳信息职业技术学院' },
        { value: 'lisi', label: '李四', brief: '深圳职业技术学院' },
        { value: 'wangwu', label: '王五', brief: '深圳大学' }
      ]
    }
  },
  watch: {
    options: function () {
      if (this.option.length === this.options.length) {
        this.isAll = true
      } else {
        this.isAll = false
      }
    }
  },
  methods: {
    allChoose () {
      this.option.forEach(element => {
        this.options.push(element.value)
      })
      this.options = [...new Set(this.options)]
      this.isAll = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.toulishidanwei{
        padding 0 20px
        padding-bottom 50px
    .title{
        font-size 30px
        margin 30px 0
        span{
            font-size 60px
        }
    }
    .list{
        padding 0 30px
        margin-bottom 30px
        .info{
            margin-left  20px
            font-size 30px
            .school{
                font-size 20px
                color #a2aebd
            }
        }
    }
    .btn{
      position fixed
      width 100%
      bottom 0
      left 0
    }
}
</style>
