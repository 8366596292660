import { render, staticRenderFns } from "./MyFile1.vue?vue&type=template&id=a356f42a&scoped=true&"
import script from "./MyFile1.vue?vue&type=script&lang=js&"
export * from "./MyFile1.vue?vue&type=script&lang=js&"
import style0 from "./MyFile1.vue?vue&type=style&index=0&id=a356f42a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a356f42a",
  null
  
)

export default component.exports