<template>
  <div style="background: rgb(249 250 251)">
    <div
      class="md-example-child md-example-child-field md-example-child-field-0"
    >
      <md-field title="个人信息" :brief="bookBrief">
        <component 
          v-for="item in form.formItem"
          :key="item.prop"
          :is="item.component"
          v-bind="item.attrs"
          v-on="item.events"
          v-model="formData[item.prop]"
        ></component>
      </md-field>
      <!-- 性别选择的弹出框 -->
      <md-selector
          v-model="isShowSelector"
          default-value="男"
          :data="gender"
          @choose="chooseGender"
          title="性别"
        ></md-selector>
    </div>

    <div>
      <md-action-bar :actions="actionData"></md-action-bar>
    </div>
  </div>
</template>

<script>
import {
  Toast,
  ActionBar,
  RadioBox,
  Radio,
  Dialog,
  InputItem,
  Field,
  TextareaItem,
  FieldItem,
  Icon,
  Button,
  Selector,
  CheckBox,
} from "mand-mobile";

import publicValidator from '@/util/publicValidator.js'

export default {
  name: "userInfo",
  components: {
    [CheckBox.name]: CheckBox,
    [ActionBar.name]: ActionBar,
    [Radio.name]: Radio,
    [RadioBox.name]: RadioBox,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [FieldItem.name]: FieldItem,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [InputItem.name]: InputItem,
    [TextareaItem.name]: TextareaItem,
    [Selector.name]: Selector,
  },
  data() {
    return {
      isShowSelector: false,
      gender: [
        {
          value: '男',
          text: '男'
        },
        {
          value: '女',
          text: '女'
        }
      ],
      formData: {
        name: "",
        gender: "男",
        mobile: "",
        zhiwei: "",
        zhicheng: "",
        wechat: "",
        user_email: "",
      },
      errorMsg: {
        name: "",
        mobile: "",
        zhiwei: "",
        zhicheng: "",
        wechat: "",
        user_email: "",
      },
      bookBrief:
        "核对个人信息，如有错误请修改，该信息将与所有业务系统共用，请务检查数据正确性。",
      disabledBtn: false,
      loaded: false,
      actionData: [
        {
          text: "取消",
          onClick: () => {
            this.$router.go(-1);
          },
        },
        {
          text: "提交修改",
          disabled: false,
          loading: this.loaded,
          onClick: this.postData,
        },
      ],
    };
  },
  created() {
    this.formData = this.$store.state.userInfo
  },
  computed: {
    form() {
      return {
        formData: this.formData,
        formItem: [
          {
            prop: 'name',
            label: '姓名',
            Validator: false,
            component: InputItem,
            events: {
              blur: () => { this.formValidation('name', ['requireValidator'], '姓名不能为空') } 
            },
            attrs: {
              title: '姓名',
              placeholder: '请输入姓名',
              align: 'right',
              'is-highlight': true,
              error: this.errorMsg['name']
            },
          },
          {
            prop: 'gender',
            label: '性别',
            Validator: false,
            component: FieldItem,
            events: {
              click: this.showGenderSelector
            },
            attrs: {
              title: '性别',
              content: this.formData.gender,
              align: 'right',
            },
          },
          {
            prop: 'mobile',
            label: '手机号',
            Validator: false,
            component: InputItem,
            events: {
              blur: () => { this.formValidation('mobile', ['mobileValidator'], '请输入有效手机号码') } 
            },
            attrs: {
              title: '手机号',
              placeholder: '请输入手机号码',
              align: 'right',
              type: 'phone',
              'is-highlight': true,
              error: this.errorMsg['mobile']
            },
          },
          {
            prop: 'zhiwei',
            label: '职务',
            Validator: false,
            component: InputItem,
            events: {
              blur: () => { this.formValidation('zhiwei', ['requireValidator'], '职务不能为空') } 
            },
            attrs: {
              title: '职务',
              placeholder: '请输入职务',
              align: 'right',
              'is-highlight': true,
              error: this.errorMsg['zhiwei']
            },
          },
          {
            prop: 'zhicheng',
            label: '职称',
            Validator: false,
            component: InputItem,
            events: {
              blur: () => { this.formValidation('zhicheng', ['requireValidator'], '职称不能为空') }
            },
            attrs: {
              title: '职称',
              placeholder: '请输入职称',
              align: 'right',
              'is-highlight': true,
              error: this.errorMsg['zhicheng']
            },
          },
          {
            prop: 'wechat',
            label: '微信号',
            Validator: false,
            component: InputItem,
            events: {
              blur: () => { this.formValidation('wechat', ['requireValidator'], '微信号不能为空') }
            },
            attrs: {
              title: '微信号',
              placeholder: '请输入微信',
              align: 'right',
              'is-highlight': true,
              error: this.errorMsg['wechat']
            },
          },
          {
            prop: 'user_email',
            label: '邮箱',
            Validator: false,
            component: InputItem,
            events: {
              blur: () => { this.formValidation('user_email', ['emailValidator'], '请输入有效邮箱') } 
            },
            attrs: {
              title: '邮箱',
              placeholder: '请输入邮箱',
              align: 'right',
              'is-highlight': true,
              error: this.errorMsg['user_email']
            },
          },
        ]
      }
    }
  },
  methods: {
    showGenderSelector() {
      this.isShowSelector = true
    },
    chooseGender(data) {
      this.formData.gender = data.value
    },
    postData() {
      let inputValidation = false
      let selectorValidation = this.formData.gender !== ''
      inputValidation = Object.keys(this.errorMsg).every(item => {
        return this.errorMsg[item] === ''
      })
      if(inputValidation && selectorValidation) {
        this.$API.user.changeUserInfo(this.formData).then(res => {
          if (res.errno === 0) {
            Toast.succeed(res.msg)
            this.$router.push('/CollageInfo/user')
          } else {
            Toast.failed(res.msg)
          }
        })
      } else {
        console.log('验证不通过，不能提交')
      }
    },
    formValidation(prop, rule = [], msg = '请输入有效内容') {
      let result = false
      result = rule.every(item => {
        return publicValidator[item](this.formData[prop])
      })

      if(result) {
        this.errorMsg[prop] = ''
      } else {
        this.errorMsg[prop] = msg
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
.md-field-header {
  margin-bottom: 0;
}

.md-example-child-field-0 {
  .md-field-action {
    height: 36px;
    align-items: flex-end;

    .action-container {
      display: flex;
      align-items: center;

      .md-icon {
        margin-left: 10px;
        color: #C5CAD5;
        font-size: 16px;
        width: auto;
        height: auto;
        line-height: normal;
      }
    }
  }
}

.demo2-footer {
  display: flex;
  justify-content: space-between;
  color: #999;
  font-size: 24px;
}
</style>
 