import axios from "../util/request"

export default {
	registerUser(userInfo) {
		return axios.post("index/User/registerUser",userInfo)
	},
	// 普通单位成员注册用户
	userregister(collage_id, userInfo) {
		return axios.post("index/register/userregister",{ collage_id, ...userInfo })
	},
	// 用户列表
	userRegisterList(){
		return axios.post("index/collage/userRegisterList",{ page:1,rows:99 })
	},
	//人员详情,注册人员详细信息
	userRegisterDetail(id){
		return axios.post("index/collage/userRegisterDetail",{ id,status })
	},
	//注册审核
	approve(id,user_status){
		return axios.post("index/collage/approve",{ id,user_status })

	}
}
