<template>
  <div class="toupiaoContent">
    <md-notice-bar v-show="!canTou">{{canToumsg}}</md-notice-bar>
    <md-bill>

      <div class="header-slot" slot="header">
        <span id="goback"   @click="goback"> <md-icon
            name="arrow-left"
            size="lg"

        ></md-icon>返回上一页</span>


        <h1 class="title">{{title}}</h1>
<!--        <p class="desc">仔细阅读投票说明，关注您的利益、义务 <br>1 对所有高校/企业进行表决 <br>
          2 点击保存按钮。<br>
          3 点击提交投票按钮进行投票。</p>-->
        <div class="myaction" v-show=" canTou && !isTou">
          <div class="allAction">
            <md-button
                type="primary"
                inline
                class="btn"
                @click="allAgree(1)"
                size="small"
            >全部同意
            </md-button
            >
            <md-button
                type="primary"
                inline
                plain
                class="btn btn2"
                size="small"
                @click="someAgree"
            >部分同意
            </md-button>
          </div>
          <div class="allAction" style="margin-bottom: 20px">
            <md-button
                type="primary"
                inline
                plain
                class="btn"
                size="small"
                @click="saveRes"
            >保存
            </md-button>
            <md-button
                type="warning"
                inline
                class="btn btn2"
                size="small"
                @click="submitToupiao"
            >提交投票
            </md-button>
          </div>
        </div>
      </div>
      <div>
        <md-cell-item
            :title="item.collage_id+'  '+item.label"
            class="houxuanren"
            v-for="(item, index) in houxuandanwei"
            :key="index"
            :brief="item.brief"
        >
          <div class="holder" slot="children">
            <md-radio
                :name="1"
                v-model="item.value"
                label="同意"
                inline
                :disabled="isTou &&  (item.value !=1) "
                @change="changeRaido"
            />
            <md-radio
                :name="0"
                v-model="item.value"
                label="不同意"
                inline
                :disabled="isTou  &&  (item.value !=0) "
                @change="changeRaido"
            />
            <md-radio
                :name="-1"
                v-model="item.value"
                label="弃权"
                inline
                :disabled="isTou  &&  (item.value !=-1) "
                @change="changeRaido"
            />
          </div>

        </md-cell-item>

      </div>
         <div class="footer-slot" slot="footer">
           <div class="myaction" v-show=" canTou && !isTou">
             <div class="allAction">
               <md-button
                   type="primary"
                   inline
                   class="btn"
                   @click="allAgree"
                   size="small"
               >全部同意
               </md-button
               >
               <md-button
                   type="primary"
                   inline
                   plain
                   class="btn btn2"
                   size="small"
                   @click="someAgree"
               >部分同意
               </md-button>
             </div>
             <div class="allAction" style="margin-bottom: 20px">
               <md-button
                   type="primary"
                   inline
                   plain
                   class="btn"
                   size="small"
                   @click="saveRes"
               >保存
               </md-button>
               <md-button
                   type="warning"
                   inline
                   class="btn btn2"
                   size="small"
                   @click="submitToupiao"
               >提交投票
               </md-button>
             </div>
           </div>
            </div>
    </md-bill>

    <md-dialog
        :closable="false"
        layout="column"
        v-model="actDialog.open"
        :btns="actDialog.btns"
    >

      <div class="dialog-banner" slot="header">
        <p class="actionTitle" v-show="butongyiList.length != 0">您真的不同意如下单位吗？</p>
        <p class="actoinItem" v-for="(item,index) in butongyiList" :key="index">
          {{item.label}}
        </p>
        <p class="actionTitle" v-show="qiquanList.length != 0">您真的要对如下单位弃权吗？</p>
        <p class="actoinItem" v-for="(item,index) in qiquanList" :key="index">
          {{item.label}}
        </p>
      </div>
    </md-dialog>
    <md-popup v-model="lunShow">
      <div class="md-example-popup md-example-popup-center">
        {{lunMsg }}
      </div>
    </md-popup>
    <md-popup v-model="submitLoading" :mask-closable="false" :large-radius="true">
      <div class="md-example-popup md-example-popup-center">
        <md-activity-indicator
            class="md-activity-indicator-css"
        >loading...</md-activity-indicator>      </div>
    </md-popup>

    <md-popup v-model="butongyiDialog" >
      <div class="md-example-popup md-example-popup-center"   @click="butongyiDialog = false">
        请在不同意候选人下方点击 <br>“不同意”或“弃权”按钮。
      </div>
    </md-popup>
  </div>


</template>

<script>
import {
  ActionBar,
  NoticeBar,
  FieldItem,
  CheckList,
  Picker,
  Steps,
  Toast,
  Bill,
  Radio,
  DetailItem,
  Dialog,
  Icon,
  Tag,
  Field,
  CellItem,
  Switch,
  Captcha,
  Selector,
  InputItem,
  RadioList,
  ActivityIndicator,
  Popup,
  PopupTitleBar
} from "mand-mobile";

export default {
  components: {
    [Selector.name]: Selector,
    [Steps.name]: Steps,
    [CheckList.name]: CheckList,
    [Picker.name]: Picker,
    [ActionBar.name]: ActionBar,
    [FieldItem.name]: FieldItem,
    [Radio.name]: Radio,
    [InputItem.name]: InputItem,
    [Bill.name]: Bill,
    [DetailItem.name]: DetailItem,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [Switch.name]: Switch,
    [Captcha.name]: Captcha,
    [RadioList.name]: RadioList,
    [Dialog.name]: Dialog,
    [NoticeBar.name]: NoticeBar,
    [Popup.name]: Popup,
    [PopupTitleBar.name]: PopupTitleBar,
    [ActivityIndicator.name]: ActivityIndicator,

  },
  name: 'Touchangwulishi',
  data() {
    return {
      // 不同意按钮提示
      butongyiDialog: false,
      // 不是这一轮
      lunShow:false,
      // 轮的提示
      lunMsg:'',
      // 是否投票了；
      isTou:false,
      actDialog: {
        open: false,
        btns: [
          {
            text: '我再想想',
            type: 'danger',
            handler: this.zaixiangxiang,
          },
          {
            text: '确定',
            handler: this.sumbit,
          },
        ],
      },
      title:'未识别的路由',
      id:0,
      // 是否已经投票
      isToupiao: false,
      // 是否可以投票
      canTou:false,
      // 投票选项
      marriage: [],
      isAll: false,
      // 是否保存
      isSave: false,
      // 理事单位
      lishidanwei: [],
      // 常务理事
      changwulishi: [],
      // 班子成员列表
      banzi: [],
      // 候选单位
      houxuandanwei:[],
      // 不同意的单位
      butongyiList:[],
      // 弃权的人
      qiquanList:[],
      // 不能投票的原因
      canToumsg:'',
      // 是否提交中
      submitLoading:false
    }
  },
  mounted() {
    document.title = '投票系统'
    let id = this.$route.params.id
    this.id = id
    this.toupiaoInit(id)

  },
  watch: {
    houxuandanwei:{
      handler(newVal,oldVal){
        this.isSave = false
        console.log('  this.isSave = false')
      },
      deep:true
    }
  },
  methods: {
    toupiaoInit(id) {
     this.$API.toupiao.toupiaoInit(id).then(res=>{
        this.canToumsg = res.data.canTouMsg
        this.isTou = res.data.isTou
        this.canTou = res.data.canTou
       console.log(id > res.data.lun.lun)
       console.log(id < res.data.lun.lun)
       if (id > res.data.lun.lun){
         // 还么开始
         console.log('当前投票还未开始')
         this.lunMsg = '当前投票还未开始'
         this.lunShow = true
       }
       if (id < res.data.lun.lun){
         this.lunMsg = '当前投票已结束'
         this.lunShow = true
         // 结束了
         console.log('当前投票已结束')
       }
       // 加载数据
       switch (id) {
         case '1':
           this.title = '理事单位选票'
           this.getCollage()
           break;
         case '2':
           this.title = '常务理事单位选票'
           this.getChangwuLishi()
           break;
         case '3':
           this.title = '领导班子成员单位选票'
           this.getBanzi()
           break;
         default:
           console.log('未识投票模式')
           break;
       }

       // 设置投票按钮
     })
    },
    goback(){
      this.$router.history.go(-1)
    },
    // 获取常务理事
    getChangwuLishi() {
      Toast.loading('加载中...')
      this.$API.toupiao.getChangwulishiList().then(res => {
        if (res.errno !=0){
          this.isTou = true
          Toast.failed(res.msg, 5000)

        }
        setTimeout(() => {
          Toast.hide()
        }, 2000)
        res.data.map(item => {
          if (!this.canTou){
            this.isTou = true
            item.res = undefined
          }
          this.houxuandanwei.push({
            id: item.id,
            value: item.res,
            label: item.collage.collage_name,
            brief:'',
            collage_id:item.collage.collage_id,
          })
        })
      })
    },
    // 班子成员
    getBanzi() {
      Toast.loading('加载中...')
      this.$API.toupiao.getBanziList().then(res => {
        if (res.errno !=0){
          this.isTou = true
          Toast.failed(res.msg, 5000)

        }
        setTimeout(() => {
          Toast.hide()
        }, 2000)
        this.banzi = res.data
        res.data.map(item => {
          if (!this.canTou){
            this.isTou = true
            item.res = undefined
          }
          this.houxuandanwei.push({
            id: item.id,
            value: item.res ,
            brief: item.collage.collage_name,
            label:item.description,
            collage_id:item.collage.collage_id,
          })
        })
      })
    },
    // 学习名称
    getCollage() {
      Toast.loading('加载中...')
      this.$API.toupiao.getCollageList().then(res => {
        if (res.errno !=0){
          this.isTou = true
           Toast.failed(res.msg, 5000)
        }
        setTimeout(() => {
          Toast.hide()
        }, 2000)
        // this.changwulishidanwei
        res.data.map(item => {
          if (!this.canTou){
            this.isTou = true
            item.res = undefined
          }
          this.houxuandanwei.push({
            id: item.id,
            value: item.res,
            label: item.collage_name,
            brief:'',
            collage_id:item.collage_id,

          })
        })
      })
    },
    // 提交投票
    submitToupiao() {
      if (!this.isSave) {
        return Toast.failed('请先点击"保存"按钮', 5000)
      } else {
        this.isTou = true
        this.butongyiList =[]
        // 循环拿到不同意的东西
        this.houxuandanwei.map(item=>{
          if (item.value === 0){
            this.butongyiList.push({
              label:item.label
            })
          }
          if (item.value === -1){
            this.qiquanList.push({
              label:item.label
            })
          }
        })
        if (this.butongyiList.length !== 0){
          this.actDialog.open = true
          return
        }else if(this.qiquanList.length !== 0){
          this.actDialog.open = true
          return
        }
        // 提交投票
        this.sumbit()
      }

    },
    // 再想一想的操作
    zaixiangxiang(){
      this.actDialog.open = false
      this.isTou = false
      this.butongyiList = []
      this.qiquanList = []
    },
    // 部分同意
    someAgree() {
      this.butongyiDialog = true
       this.allAgree()
    },
    checkChoose() {
     return   this.houxuandanwei.every(item => {
        if (item.value == undefined) {
          return false
        } else {
          return true
        }
      })
    },
    // 保存
    saveRes() {
      if (this.checkChoose()) {
        Toast.succeed('保存成功', 4000)
        this.isSave = true
      } else {
        Toast.failed('您尚有未表决的单位', 5000)
      }
    },
    changeRaido(e) {
      console.log(111)
      console.log(e)
    },
    // 全部同意
    allAgree(type) {

      this.houxuandanwei.map((item, index) => {
        this.$set(this.houxuandanwei[index], 'value', 1);
      });
      if (type == 1){
        setTimeout(()=>{
          this.isSave = true
          console.log('save!')
        },1000)

      }


    },
    sumbit(){
      this.submitLoading = true
      this.actDialog.open = false

      this.$API.toupiao.touPiao(this.id,this.houxuandanwei).then(res=>{
        console.log('sumbit success')

        this.submitLoading = false
        if (res.errno == 0){
          console.log(res)
          this.isTou = true
          Toast.succeed('提交成功', 5000)
        }else{
          Toast.failed(res.msg, 5000)

        }
        if (res.errno == 4914){
          return this.$router.back()
        }

      }).catch(()=>{
        this.isTou = false
        this.submitLoading = false
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
/deep/ .md-bill-header{
  padding-top 0
}
.myaction
  //position fixed
  //bottom 0
  background white
  left 0
  right 0
  width 100%
  z-index 9999
  .allAction
    margin 0px
    margin-top 40px


.toupiaoContent
  //padding 0 20px

  .md-bill
    background-color #FFF
    box-shadow 0 6px 24px rgba(17, 26, 52, .05)

    &.md-water-mark
      overflow visible

    .md-bill-neck
      &:before, &:after
        content ''
        position absolute
        top 0
        width 36px
        height 36px
        border-radius 18px
        background-color #F3F4F5

      &:before
        left -46px

      &:after
        right -46px

    .header-slot
      //padding 40px 0 20px 0
      width 100%
      .title
        color #111A34
        font-size 60px
        font-family Songti SC
        margin 0
        //line-height 1

      .desc
        margin-top 16px
        color #858B9C
        font-size 26px

    .footer-slot
      padding 32px 0
      color #858B9C
      font-size 22px
      line-height 1.5
      border-top solid 1px #E1E4EB
.actoinItem
  font-size 40px
  text-align center
  margin 10px 0
.actionTitle
  font-size 50px
  text-align center
  margin 20px 20px
.houxuanren
  margin-bottom 20px
  font-weight 900
  .holder
    font-size 30px
    color #858B9C
    display flex
    justify-content space-between
#goback
  font-size 40px
.md-example-popup
  position relative
  font-size 28px
  font-family DINPro
  font-weight 500
  box-sizing border-box
  text-align center
  background-color #FFF
.md-example-popup-center
  padding 50px
  border-radius radius-normal
</style>
