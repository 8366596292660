<template>
  <div class="container">
    <div class="navigationBar">
      <img class="icon1" @click="back" :src="icon1" alt="" />
      {{ name || "导航栏" }}
      <img class="icon2" :src="icon2" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "navigationBar",
  data() {
    return {
      icon1: require("@/assets/icon/navigationBar/leftArrow.png"),
      icon2: require("@/assets/icon/navigationBar/setting1.png"),
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  props: {
    name: "",
  },
};
</script>

<style scoped>
.container {
    height: 120px;
    width: 100%;
}
.navigationBar {
  width: 100%;  
  position: fixed;
  z-index: 500;
  padding: 40px 25px;
  box-sizing: border-box;
  top: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  border-bottom: 1px solid rgb(218, 211, 211);
}
.navigationBar img {
  position: absolute;
  height: 40px;
  width: 40px;
}
.navigationBar .icon1 {
  left: 25px;
}
.navigationBar .icon2 {
  right: 25px;
}
.block {
  height: 50px;
  width: 100%;
}
</style>