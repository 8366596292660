import axios from '../util/request'

export default {
    getGroupList(groupName) {
        return axios.get(`index/collage/showxiezuozu`, {
            params: {
                xiezuozu: groupName
            }
        })
    },
    showCollage() {
        return axios.get(`manage/collage/showCollage`, {
            params: {
                rows: 999
            }
        }) 
    }
}