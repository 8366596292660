<template>
  <div class="button">
    <div class="md-example-section">
      <md-button type="default" inline @click="all">全部同意</md-button>
      <md-button type="primary" inline @click="oneceByOne" v-if="bol"
        >手动逐一表决</md-button
      >
      <md-button type="warning" inline @click="save" v-else>保存</md-button>
    </div>
  </div>
</template>

<script>
import { Button, Toast } from 'mand-mobile'
export default {
  name: 'button',
  props: ['isAll'],
  components: {
    [Button.name]: Button
  },
  data () {
    return {
      bol: true
    }
  },
  watch: {
    isAll: function () {
      if (this.isAll) {
        this.bol = false
      }
    }
  },
  methods: {
    all () {
      this.$emit('all')
    },
    oneceByOne () {
      if (this.isAll) {
        this.bol = false
      } else {
        Toast.info('请先选择全部同意')
      }
    },
    save () {
      console.log('save')
    }
  }
}
</script>

<style lang="stylus" scoped>
.button{
    // position relative
    .md-example-section{
        width 100%
        position absolute
        left 0
        bottom 0
        display flex
        justify-content space-between
    }
    .md-button{
        width 48%
    }
}
</style>
