<template>
    <div class="content">
        <div style="margin: 0.3rem;font-size: 0.6rem">我的二次推举情况</div>
        <p class="title">非提名代表</p>
        <table border="1" style="font-size: 0.3rem;width: 100%">
            <tr>
                <td>学校名称</td>
                <td>学校代码</td>
                <td>姓名</td>
                <td>表决</td>
            </tr>
            <tr v-for="(item,index) in dataRes.feitiming" :key="index">
                <td>{{item.collage_name}}</td>
                <td>{{item.collage_id}}</td>
                <td>{{item.name}}</td>
                <td>{{item.res}}</td>
            </tr>
        </table>

        <md-action-bar :actions="actiondata"></md-action-bar>
    </div>

</template>

<script>
    import {ActionBar} from 'mand-mobile'

    export default {
        name: "myTp",
        components: {
            [ActionBar.name]: ActionBar,
        },
        data() {
            return {
                actiondata: [
                    {
                        text: '返回',
                        onClick: () => {
                            this.$router.go(-1)
                        },
                    },
                ],
                dataRes: {
                    timing: [],
                    feitiming: [],
                }
            };
        },
        mounted() {
            this.$API.user.showMytoupiao(2).then(res => {
                this.dataRes = res.data
            })
        },
        methods: {},
    };
</script>

<style scoped>
    .title {
        font-size: 0.5rem;
        margin: 0.3rem
    }

    .mytable {
        width: 100%;
    }
</style>
