<template>
  <div class="menusrow">
    <div v-for="(item, index) in menus" :key="index">
      <h3 class="servicesTitle">{{ item.title }}</h3>
      <masonry :cols="4" type="flex" justify="space-around">
        <div
            class="menu"
            v-for="(menu, indexMenu) in item.menu"
            :key="indexMenu"
            @click="action(menu)"
        >
          <div style="margin: 10px">
            <img style="width: 45px" :src="menu.img"/>
            <p style="font-size: 0.35rem">{{ menu.name }}</p>
          </div>

        </div>

      </masonry>
      <hr class="divider" />
    </div>

  </div>
</template>

<script>
import {Icon, Dialog, Button} from "mand-mobile";

export default {
  name: "IndexMenus",
  components: {
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  data() {
    return {
      banner: "/wechat/fuwuhao8cm.png",
      menus: [
        {
          title: "分会信息",
          menu: [
            {
              img: require('@/assets/menuicon/0.png'),
              name: '分会简介',
              url:'/news2/9/分会简介'
            },
            {
              img: require('@/assets/menuicon/1.png'),
              name: '启事与通知',
              url:'/news2/2/启事与通知'
            },
            {
              img: require('@/assets/menuicon/2.png'),
              name: '工作动态',
              url:'/news2/1/工作动态'
            },
            {
              img: require('@/assets/menuicon/3.png'),
              name: '大事记',
              url:'/news2/6/大事记'
            },
            /*{
              img: require('@/assets/menuicon/4.png'),
              name: '联系我们',
              url:'/toupiao'
            }*/
          ],
        },
        {
          title: "资料查询",
          menu: [
            {
              img: require('@/assets/menuicon/5.png'),
              name: '制度诠释',
              url:'/news/3/制度诠释'
            },
            {
              img: require('@/assets/menuicon/6.png'),
              name: '业务交流',
              url:'/news/5/业务交流'
            },
            {
              img: require('@/assets/menuicon/7.png'),
              name: '成果展示',
              url:'/news/7/成果展示'

            },
            {
              img: require('@/assets/menuicon/8.png'),
              name: '案例分享',
              url:'/news/4/案例分享'
            }
          ],
        },
        {
          title: "工作平台",
          menu: [
            {
              img: require('@/assets/menuicon/9.png'),
              name: '科研平台',
              url:{
                path: '/sso/1',
                query: {
                  ssourl: 'http://gzfh.zbvc.edu.cn/LoginDandian.aspx'
                } // 将跳转的路由path作为参数，登录成功后跳转到该路由
              }
            },
            {
              img: require('@/assets/menuicon/10.png'),
              name: '培训平台',
              url:{
                path: '/sso/2',
              }

            },
            {
              img: require('@/assets/menuicon/11.png'),
              name: '会员平台',
              url:'/user'
            },
            {
              img: require('@/assets/menuicon/12.png'),
              name: '办公平台',
              url:{
                path: '/sso/2',
              }
            },

          ],
        },

      ],
      dialogData: {
        isOpen: false,
        msg: "msg",
        title: "title",
        banner: "",
        btns: [
          {
            text: "知道啦",
            handler: (this.isOpen = false),
          },
        ],
      },
    };
  },
  mounted() {
    /*this.$API.user.getUserInfo().then(res => {
      if (res.errno == 0) {
        if (res.data.daibiaowai == '') {
          this.menus[0].menu.push({
            name: "投票",
            icon: "lianxi",
            url: "/toupiao",
            urltype: "orign",
            click: "router",
          })
        } else {
          Dialog.alert({
            title: '提示',
            content: res.data.daibiaowai,
            confirmText: '确定',
          })
        }
        if (res.data.erci == 1) {
          console.log('erci')
          this.menus[0].menu.push({
            name: "二次投票",
            icon: "lianxi",
            url: "/toupiao2",
            urltype: "orign",
            click: "router",
            erci: true,
          })
        }
        if (res.data.is_admin == 1) {
          console.log('is_admin')
          this.menus[0].menu.push({
            name: "统计",
            icon: "jiekuanrenshenfenzhengxinxixiugai",
            url: "/result",
            urltype: "orign",
            click: "router",
            is_admin: true,
          })
        }
      }

    })*/
  },
  methods: {
    // 签到
    qiandiao(id) {
      this.$API.qiandao.qiandao(id).then(res => {
        Dialog.alert({
          title: '提示',
          content: res.msg,
          confirmText: '确定',
        })
      })
    },

    action(item) {
      if (item.urlType == 'outlink'){
        window.location = item.url
      }else if(item.urlType == 'action') {
        item.action()

      }else{
        this.$router.push(item.url);
      }
    },
    dialog(menu) {
      console.log(menu);
      this.dialogData.isOpen = false;
      this.dialogData.msg = "msg";
      this.dialogData.title = "title";
      this.dialogData.banner = "";
      this.dialogData.isOpen = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.dialog-banner {
  img {
    display: block;
    width: 100%;
  }
}

.menusrow {
  margin: 0 auto;
  text-align: center;
  /* border-style:  solid; */
  border-radius: 40px;
  //padding: 30px 10px;
  //box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
  background: #ffffff;

  p {
    font-size: 20px;
  }

  .servicesTitle {
    margin:20px 0;
    /* letter-spacing 6px */
    font-size: 40px;
    /* padding-left 30px */
    text-align center
    top: 20px;
  }
  .divider{
    height 20px
    border 0
    background-color  rgb(248, 248, 248)
  }


}
</style>
