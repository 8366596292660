/* 校验规则 */

export default {
    requireValidator,
    mobileValidator,
    emailValidator
}

/* 验证输入信息不能为空 */
function requireValidator(inputData) {
    return Boolean(inputData)
}

/* 手机号码格式验证 */
function mobileValidator(inputData) {
    return /^1(3|4|5|6|7|8|9)\d{9}$/.test(inputData)
}

/* 邮箱格式验证 */
function emailValidator(inputData) {
    return /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(inputData)
}

/* 身份证号码验证 */
function idCardValidator(inputData) {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(inputData)
}