<template>
  <div>
    <div class="head">
      <IndexSwiper></IndexSwiper>
    </div>
    <div class="index2">
      <div
        class="contentCard"
        v-for="(card, index) in cardList"
        :key="index">
        <p class="title">{{ card.title }}</p>
        <div class="list">
          <div
            class="item"
            v-for="(item, i) in card.list"
            @click="goTo(item.url)"
            :key="i">
            <img :src="item.logoUrl" alt="" class="logo" />
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
    <p class="footer">联系我们：<a href="mailto:gz13411851062@163.com">gz13411851062@163.com（点击启动邮箱）</a>
       <br/>Copyright©中国教育会计学会高职分会&nbsp;2020-{{ new Date().getFullYear() }} <br>自动构建时间：{{ BUILDTEIME }}<br>技术支持：萌点云科技（深圳）有限公司<br>技术支持电话：075533122297</p>
    </div>
  </div>
</template>

<script>
import IndexSwiper from "@/components/Index/IndexSwiper";

export default {
  name: "index2",
  components: {
    IndexSwiper,
  },
  data() {
    return {
      cardList: [
        {
          title: "分会信息",
          list: [
            {
              logoUrl: require("@/assets/menuicon/0.png"),
              name: "分会简介",
              url: "/news2/9/分会简介",
            },
            {
              logoUrl: require("@/assets/menuicon/1.png"),
              name: "启事与通知",
              url: "/news/2/启事与通知",
            },
            {
              logoUrl: require("@/assets/menuicon/2.png"),
              name: "工作动态",
              url: "/news/1/工作动态",
            },
            {
              logoUrl: require("@/assets/menuicon/3.png"),
              name: "大事记",
              url: "/news/6/大事记",
            },
            /*{
              img: require('@/assets/menuicon/4.png'),
              name: '联系我们',
              url:'/toupiao'
            }*/
          ],
        },
        {
          title: "资料查询",
          list: [
            {
              logoUrl: require("@/assets/menuicon/5.png"),
              name: "制度诠释",
              url: "/news/3/制度诠释",
            },
            {
              logoUrl: require("@/assets/menuicon/6.png"),
              name: "业务交流",
              url: "/news/5/业务交流",
            },
            {
              logoUrl: require("@/assets/menuicon/7.png"),
              name: "成果展示",
              url: "/news/7/成果展示",
            },
            {
              logoUrl: require("@/assets/menuicon/8.png"),
              name: "案例分享",
              url: "/news/4/案例分享",
            },
          ],
        },
        {
          title: "工作平台",
          list: [
            {
              logoUrl: require("@/assets/menuicon/9.png"),
              name: "科研平台",
              url: {
                path: "/sso/1",
                query: {
                  ssourl: "http://gzfh.zbvc.edu.cn/LoginDandian.aspx",
                }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
              },
            },
            {
              logoUrl: require("@/assets/menuicon/10.png"),
              name: "培训平台",
              url: {
                path: "/sso/2",
              },
            },
            {
              logoUrl: require("@/assets/menuicon/11.png"),
              name: "会员平台",
              url:'/user'
            },
            {
              logoUrl: require("@/assets/menuicon/12.png"),
              name: "办公平台",
              url: {
                path: "/sso/2",
              },
            },
          ],
        },
      ],
      BUILDTEIME: "",
    };
  },
  mounted() {
    this.BUILDTEIME = process.env.VUE_APP_BUILDTEIME;
  },
};
</script>

<style scoped>
.head {
    position: relative;
}
.head:after {
    position: absolute;
    bottom: -35px;
    left: 0;
    content: '';
    width: 100%;
    height: 150px;
    background-image: linear-gradient(transparent 5%, #ECEDEE);
}
.index2 {
  padding: 0 25px;
  padding-top: 20px;
  background-color: #ecedee;
  //min-height: 100vh;
}
.contentCard {
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 50px 0px;
}
.contentCard .title {
  padding-left: 30px;
  color: #6d183b;
  font-size: 40px;
  margin-bottom: 50px;
  position: relative;
}
.contentCard .title:before {
  content: "";
  position: absolute;
  left: 0px;
  height: 100%;
  width: 10px;
  background-color: #6d183b;
  border-radius: 5px;
}
.contentCard .list {
  display: flex;
  justify-content: space-around;
}
.contentCard .list .item {
  font-size: 30px;
  color: #020f22;
  text-align: center;
}
.contentCard .list .item img {
  height: 100px;
  width: 100px;
  overflow: hidden;
  background-color: #ecedee;
  border-radius: 500px;
  margin-bottom: 15px;
}
.footer {
  font-size: 20px;
  text-align: center;
  //margin: 50px 0 10px 0;
  //letter-spacing: 1px;
  line-height: 30px;
}
</style>
