<template>
	<div class="md-example-child md-example-child-input-item-5">
		<img class="avatar" src="../../public/logo.jpeg" />
		<md-field>
			<md-input-item
				type="phone"
				title="手机号码"
				v-model="phone"
				:error="error"
				clearable
				placeholder="点击输入手机号"
				align="right"
			></md-input-item>
			<md-captcha
				:maxlength="4"
				v-model="captchaShow"
				brief="最新验证码依然有效，请勿重发"
				@send="send"
				@submit="submit"
				:appendTo="appendTo"
			>
				验证码已发送至{{ phone }}
			</md-captcha>
			<md-button class="lobtn" type="primary" @click="login">登录</md-button>
		</md-field>
	</div>
</template>

<script>
import { InputItem, Field, Dialog } from "mand-mobile"
import { Captcha } from "mand-mobile"
import { Toast } from "mand-mobile"
import api from "@/api"
import { setToken } from "@/util/auth"
// console.log("api.user.getVerificationCode:", api.user.getVerificationCode);

export default {
	name: "input-item-demo",
	components: {
		[InputItem.name]: InputItem,
		[Field.name]: Field,
		[Captcha.name]: Captcha,
	},
	data() {
		return {
			// fullWidth: document.documentElement.clientWidth,
			system: true,
			phone: "",
			error: "",
			captchaShow: false,
			bankCardNo: "",
			isError: false,
			appendTo: document.querySelector(".doc-demo-box-priview") || document.body,
			avatar:
				"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.51yuansu.com%2Fpic3%2Fcover%2F03%2F30%2F91%2F5b83bbbb4c114_610.jpg&refer=http%3A%2F%2Fpic.51yuansu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1611511955&t=a68b6e6cc55bf40af73b83cef0e9c904",
		}
	},
	created() {
		window.addEventListener("resize", this.handleResize)
	},
	beforeDestroy: function() {
		window.removeEventListener("resize", this.handleResize)
	},
	methods: {
		handleResize(event) {
			this.fullWidth = document.documentElement.clientWidth
		},
		checkBankCard() {
			if (this.bankCardNo && this.bankCardNo.substr(0, 4) !== "6222") {
				this.isError = true
			} else {
				this.isError = false
			}
		},
		bankCardTip() {
			Dialog.alert({
				content: "以6222开头",
			})
		},

		async login() {
			if (!this.phone) {
				this.error = "手机号码无效"
				return
			}
			this.error = ""
			// 获取验证码
			let res = await api.user.getVerificationCode({ mobile: this.phone })
			if (res.errno === 0) {
				this.captchaShow = true
			} else {
				Dialog.alert({ content: res.msg })
			}
		},

		send(e) {
			console.log("e:", e)
		},

		async submit(code) {
			console.log("code:", code)
			// 开始登录
			let res = await api.user.doLogin({ mobile: this.phone, code })
			console.log("doLogin res:", res)
			this.captchaShow = false
			if (res.errno === 0) {
				// 设置token
				setToken(res.data.token)
				let res2 = await api.user.getUserInfo()
				console.log("getUserInfo res2:", res2)
				if (res2.errno === 0) {
					this.$store.commit("changeUserInfo", res2.data)
				} else {
					// 若登录失败，提示失败信息
					Dialog.alert({ content: res.msg })
				}
				Toast.succeed("登录成功", 3000, true)
        // 判断有没有这个参数
        let redirect = this.$route.query.redirect

        if (redirect == ''){
          this.$router.push("/")
        }else{
          this.$router.push(redirect)
        }
			} else {
				// 若登录失败，提示失败信息
				Dialog.alert({ content: res.msg })
			}
		},
	},
}
</script>

<style lang="stylus">

.bottom{
  padding-bottom: 0rem !important;
}
.md-example-child-input-item-5 {
  .error {
    float: left;
    width: 100%;

    .error-action {
      float: right;
      color: #5878B4;
    }
  }

  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lobtn {
  margin-top: 50px;
}


.avatar {
   width: auto;
   height: auto;
   max-width: 50%
   max-height:50%;
   display: block;
}
.md-example-child-captcha-1
 height 650px
  padding 30px 0
  .md-number-keyboard
    margin-top 400px
</style>
