<template>
  <div id="app">
    <router-view />

  </div>
</template>
<script>
import { NoticeBar } from "mand-mobile";
import { Dialog } from "mand-mobile";
import api from "@/api";

export default {
  components: {
    [NoticeBar.name]: NoticeBar,
  },
  mounted() {
    //this.userInfoInit()
  },
  methods: {
    //判断是否是微信浏览器的函数
    async userInfoInit() {
      let res2 = await api.user.getUserInfo()
      console.log("userInfoInit res2:", res2)
      if (res2.errno === 0) {
        this.$store.commit("changeUserInfo", res2.data)
      } else {
        // 若登录失败，清掉token

      }
    }

  },
};
</script>
<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  height: 100%;
  /*background-color: #f1f1f1;*/
}
</style>

