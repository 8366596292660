<template>
    <div class="imgSwiper">
        <md-swiper
                ref="swiper"
                :is-prevent="false"
                :useNative-driver="false"
                transition="fade"
                :has-dots="false"
        >
            <md-swiper-item :key="index" v-for="(item, index) in swiper">
                <img :src="item"
                     class="img-item"
                     @click="showViewer"
                />
            </md-swiper-item>
        </md-swiper>
        <md-image-viewer
                v-model="isViewerShow"
                :list="swiper"
                :has-dots="true"
                :initial-index="viewerIndex">
        </md-image-viewer>
    </div>
</template>

<script>
    import {ImageViewer, Swiper, SwiperItem} from 'mand-mobile'

    export default {
        name: "IndexSwiper",
        components: {
            [Swiper.name]: Swiper,
            [SwiperItem.name]: SwiperItem,
            [ImageViewer.name]: ImageViewer,
        },
        data() {
            return {
                isViewerShow: false,
                viewerIndex: 0,
                swiper: [
                require('@/assets/swiper/bg2.png'),
                  //  require('@/assets/swiper/2.jpg'),
                  //require('@/assets/swiper/1.jpg'),
                ],
            }
        },
        mounted() {

        },
        methods: {
            showViewer() {
                let index = this.$refs.swiper.getIndex()
                this.viewerIndex = index
                this.isViewerShow = true
            },
        },
    }

</script>


<style lang="stylus" scoped>
    .imgSwiper
        height 340px
        .md-swiper-item
            width 100% !important
        .img-item
            width 100%

            border-radius: 0px !important

</style>
