import user from "./user"
import register from "./register"
import qiandao from "./qiandao"
import toupiao from "./toupiao"
import news from "./news"
import actionUrl from "./actionUrl"
import cooperativeGroup from './cooperativeGroup'

export default {
	user,
	toupiao,
	register,
    qiandao,
	actionUrl,
	news,
	cooperativeGroup
}
