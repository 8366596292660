<template>
  <div class="md-example-child md-example-child-bill-1 content">
    <md-bill>
      <div class="header-slot" slot="header">
        <h1 class="title">{{ currentInitData.province }}投票</h1>
        <p class="desc">说明：</p>
        <p class="fwb" v-html="currentInitData.shuoming"></p>
      </div>

      <p class="newTitle" v-show="currentInitData.teyao.length != 0">特邀代表<span>的公开</span></p>
      <table align="center" v-show="currentInitData.teyao.length != 0" class="table" border="1"
             style="font-size: 0.3rem;width: 100%">
        <tr>
          <td>学校名称</td>
          <td>学校代码</td>
          <td>姓名</td>
        </tr>
        <tr v-for="(item,index) in currentInitData.teyao" :key="index">
          <td>{{ item.collage_name }}</td>
          <td>{{ item.collage_id }}</td>
          <td>{{ item.name }}</td>
        </tr>
      </table>
      <p class="newTitle">分会提名代表<span>的推举</span></p>
      <p class="newTitle2">注：等额选举<span>逐一表决</span></p>
      <md-cell-item
          :title="item.name"
          :brief="item.collage_name"
          class="houxuanren"
          v-for="(item, index) in currentInitData.timing"
          :key="index"
      >
        <div class="holder" slot="right">
          <md-radio
              name="1"
              v-model="marriage[item.id]"
              label="同意"
              inline
              :disabled="is_toupiao"

              @change="timingcancel(item.id)"
          />
          <md-radio
              @change="timingcancel(item.id)"
              name="0"
              v-model="marriage[item.id]"
              :disabled="is_toupiao"

              label="不同意"
              inline
          />
        </div>
      </md-cell-item>
      <div class="allAction" v-show="!is_toupiao">
        <md-button
            type="primary"
            inline
            class="btn"
            @click="allAgree"
            size="small"
        >全部同意
        </md-button
        >
        <md-button
            type="primary"
            inline
            plain
            class="btn btn2"
            size="small"
            @click="allDisagree"
        >手动逐一表决
        </md-button>
      </div>
      <div class="allAction" v-show="!is_toupiao">
        <md-button
            type="primary"
            inline
            plain
            class="btn"
            size="small"
            @click="cancelTiming"
        >修改
        </md-button>
        <md-button
            type="warning"
            inline
            class="btn btn2"
            @click="saveTiming"
            size="small"
        >保存
        </md-button>
      </div>
      <p class="newTitle">非提名代表<span>的推举</span></p>
      <p class="newTitle2">注：实行差额选举，限选1人（超过1人为无效）</p>
      <md-radio-list
          v-model="favorites"
          class="radioList"
          :options="currentInitData.fruits"
          icon-size="lg"

      />
      <div class="allAction" v-show="!is_toupiao">
        <md-button
            type="primary"
            inline
            plain
            class="btn"
            size="small"
            @click="cancelFeitiming"
        >修改
        </md-button>
        <md-button
            type="warning"
            inline
            class="btn btn2"
            @click="saveFeitiming"
            size="small"
        >保存
        </md-button>
      </div>
    </md-bill>
    <!-- <md-button
       type="primary"
       v-if="$store.state.userInfo.toupiao"
       @click="$router.push('/myTp')"
       >查看我的投票推举</md-button
     >-->
    <md-action-bar :actions="actiondata"></md-action-bar>
  </div>
</template>

<script>
import api from "@/api";
import {
  ActionBar,
  FieldItem,
  CheckList,
  Picker,
  Steps,
  Toast,
  Bill,
  Radio,
  DetailItem,
  Icon,
  Tag,
  Field,
  CellItem,
  Switch,
  Captcha,
  Selector,
  InputItem,
  RadioList,
  Dialog,
} from "mand-mobile";

export default {
  name: "Toupiao",
  components: {
    [Selector.name]: Selector,
    [Steps.name]: Steps,
    [CheckList.name]: CheckList,
    [Picker.name]: Picker,
    [ActionBar.name]: ActionBar,
    [FieldItem.name]: FieldItem,
    [Radio.name]: Radio,
    [InputItem.name]: InputItem,
    [Bill.name]: Bill,
    [DetailItem.name]: DetailItem,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [Switch.name]: Switch,
    [Captcha.name]: Captcha,
    [RadioList.name]: RadioList,
  },
  watch: {},
  async mounted() {
    console.log(
        "this.$store.state.userInfo.toupiao:",
        this.$store.state.userInfo.toupiao
    );
    if (this.$store.state.userInfo.toupiao) {
      this.is_toupiao = true
      this.actiondata = [
        {
          text: "已投票，查看我的推举",
          onClick: () => {
            this.$router.push('/myTp')
          },
        },
      ]
    } else {
      this.is_toupiao = false
    }

    let res = await api.user.initData({
      token: sessionStorage.getItem("token"),
    });
    console.log("initData res:", res);
    if (res.errno === 0) {
      let fruits = res.data.feitiming.map((item) => {
        let {id: value, name: label, collage_name: brief} = item;
        return {
          value,
          label,
          brief,
          disabled: this.is_toupiao == 1 ? true : false
        };
      });
      res.data.fruits = fruits;
      this.currentInitData = res.data;
    } else {
      Dialog.alert({content: res.msg});
    }

    // 获取本地缓存的选票状态数据
    let marriage = localStorage.getItem("marriage")
        ? JSON.parse(localStorage.getItem("marriage"))
        : undefined;
    let favorites = localStorage.getItem("favorites")
        ? JSON.parse(localStorage.getItem("favorites"))
        : undefined;
    if (marriage) {
      this.marriage = marriage;
    }
    if (favorites) {
      this.favorites = favorites;
    }
  },
  data() {
    return {
      // 投票了
      is_toupiao: false,
      // 提名保存
      timingSave: false,
      // 非提名保存
      feitimingSave: false,
      currentInitData: {},
      marriage: {},
      favorites: "",
      actiondata: [
        {
          text: "保存进度",
          onClick: this.saveStatus,
        },
        {
          text: "提交投票",
          onClick: this.handleSms,
          loading: false,
          disabled: false,
        },
      ],
    };
  },
  methods: {
    // 取消提名
    cancelTiming() {
      this.marriage = {}
      this.timingSave = false
    },
    // 点击非提名修改
    cancelFeitiming() {
      this.favorites = ''
      this.feitimingSave = false

    },
    // 保存非提名
    saveFeitiming() {
      if (this.favorites == '') {
        Dialog.confirm({
          title: "提示",
          content: "您尚未对非提名代表进行差额推举，此时保存系弃权，您确定弃权吗？",
          confirmText: "确定",
          onConfirm: async () => {
            console.log('非提名代表差额推举弃权确认')
            this.feitimingSave = true
            Toast.succeed('保存成功')
          }
        });
      } else {
        this.feitimingSave = true
        Toast.succeed('保存成功')
      }
    },
    // 保存提名
    saveTiming() {
      let chooseLen = Object.keys(this.marriage).length
      let timingLen = this.currentInitData.timing.length
      if (chooseLen == 0) {
        Dialog.confirm({
          title: "提示",
          content: "您尚未进行等额推举，此时保存系弃权，您确定弃权吗？",
          confirmText: "确定",
          onConfirm: async () => {
            console.log('提名代表等额推举弃权确认')
            this.timingSave = true
            Toast.succeed('保存成功')
          }
        });
      } else if (chooseLen != timingLen) {
        Dialog.alert({
          title: "提示",
          content: "您尚未完成等额选举，请逐一表决",
          confirmText: "知道了",
        })
      } else {
        Toast.succeed('保存成功')
        this.saveStatus()
        this.timingSave = true
      }

    },
    // 提名取消按钮
    timingcancel(id) {
      console.log(111);
      //this.marriage[item.id] = ''
      // this.marriage[item.id] = "1";
    },
    allAgree() {
      this.currentInitData.timing.forEach((item) => {
        console.log(item);
        // this.marriage[item.id] = "1";
        this.$set(this.marriage, item.id, "1");
      });
    },
    allDisagree() {
      Dialog.alert({content: "请手动逐一表决同意和不同意人员"});
    },
    onSelectorChoose({text}) {
      this.selectorValue = text;
    },

    async handleSms() {
      if (!this.timingSave) {
        Dialog.alert({
          title: "提示",
          content: "请点击[分会提名代表]保存按钮",
        });
        return;
      }
      if (!this.feitimingSave) {
        Dialog.alert({
          title: "提示",
          content: "请点击[非提名代表]保存按钮",
        });
        return;
      }
      console.log("handle");
      Dialog.confirm({
        title: "确认",
        content: "提交后，不能修改，不能重新投票！",
        confirmText: "确定",
        cancelText: '取消',
        onConfirm: async () => {
          let marriage = [];
          for (let key in this.marriage) {
            marriage.push({id: key, res: this.marriage[key]});
          }
          let sendData = {
            timing: marriage,
            feitiming: this.favorites,
          };
          console.log("sendData:", sendData);
          // 开始投票
          let res = await api.user.toupiao(sendData);
          console.log("res:", res);
          if (res.errno === 0) {
            Toast.succeed("投票成功", 5000, true);
            // this.$router.push("/");
            this.$router.push('/myTp')
            localStorage.removeItem("marriage");
            localStorage.removeItem("favorites");
            // 修改投票状态
            this.$store.commit("changeUserInfoToupiao", 1);
          } else {
            Dialog.alert({content: res.msg});
            // this.data[1].loading = false;
            // this.data[1].disabled = false;
          }
        },
        onCancel: () => {
          Dialog.closeAll()
          console.log("onCancel");
          // this.data[1].loading = false;
          // this.data[1].disabled = false;
        },
      });
      //
      // this.data[1].loading = true;
      // this.data[1].disabled = true;
      // if (this.mobile.length != 11) {
      //   Toast.failed("请检查手机号码输入");
      // } else {
      //   this.captchaShow = true;
      //   // 涉及删除本地缓存
      //   // localStorage.removeItem('toupiaoStatus');
      // }
    },

    saveStatus() {
      // 保存到本地
      let marriage = JSON.stringify(this.marriage);
      let favorites = JSON.stringify(this.favorites);
      // 说明：marriage中的value，1代表赞成，0代表反对
      localStorage.setItem("marriage", marriage);
      localStorage.setItem("favorites", favorites);
      Toast.succeed("保存成功", 3000, true);
    },
  },
};
</script>

<style lang="stylus" scoped>
.houxuanren {
  margin-left: 20px;
  margin-right: 20px;
}

.md-example-child-bill-1 {
  .md-bill {
    background-color: #FFF;
    box-shadow: 0 6px 24px rgba(17, 26, 52, 0.05);

    &.md-water-mark {
      overflow: visible;
    }

    .md-bill-neck {
      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background-color: #F3F4F5;
      }

      &:before {
        left: -46px;
      }

      &:after {
        right: -46px;
      }
    }

    .header-slot {
      padding: 40px 0 20px 0;

      .title {
        color: #111A34;
        font-size: 60px;
        font-family: Songti SC;
        line-height: 1;
      }

      .desc {
        margin-top: 16px;
        color: #858B9C;
        font-size: 26px;
      }
    }

    .footer-slot {
      padding: 32px 0;
      color: #858B9C;
      font-size: 22px;
      line-height: 1.5;
      border-top: solid 1px #E1E4EB;
    }
  }
}

.md-example-child-captcha {
  padding: 20px;

  .md-field {
    margin-bottom: 40px;
  }
}

.fwb {
  margin-top: 15px;
  font-size: 0.3rem;

  /deep/ p {
    text-indent: 30px;
  }
}

.allAction {
  display: flex;
  justify-content: space-around;
  margin: 30px 30px;

  .btn {
    flex: 1;
  }

  .btn2 {
    margin-left: 30px;
  }
}

.newTitle {
  margin-top: 15px;
  font-size: 70px;
  color: black;
  // border: 1px solid grey;
  padding: 15px;

  span {
    margin-left: 25px;
    color: grey;
    font-size: 30px;
  }
}

.newTitle2 {
  font-size: 30px;
  margin-top: 30px;
  color: grey;
  margin-left: 20px;

  span {
    margin-left: 20px;
  }

  margin-bottom: 15px;
}

.newTitleee {
  margin-top: 60px;
}

.radioList {
  padding-left: 30px;
}
</style>
