<template>
  <div id="index">
    <IndexSwiper></IndexSwiper>
    <IndexMenus id="indexMenus"></IndexMenus>
  </div>
</template>

<script>
import IndexSwiper from "../components/Index/IndexSwiper";
import IndexMenus from "../components/Index/IndexMenus";

export default {
  name: "Index",
  components: {
    IndexMenus,
    IndexSwiper,
  },
  beforeCreate() {
    // document.querySelector("body").setAttribute("style", "background:#f1f1f1");
  },
  activated() {
    // document.querySelector("body").setAttribute("style", "background:#f1f1f1");
  },
  deactivated() {
    document.querySelector("body").setAttribute("style", "background:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "background:#ffffff");
  },
  methods: {},
  data() {
    return {};
  },
};
</script>

<style lang="stylus" scoped>
.swiperContent {
  /* background #6ea2fe */
  //background-image: linear-gradient(top, #29b3db 0%, #9a5ae8 100%);
  //border-radius: 0 0 130px 130px;
  height: 450px;
  //padding: 20px 35px 0 35px;
  /* background: #f22568; */
}

/deep/ .content {
  position: relative;
  top: -70px;
}
</style>
