import Cookies from "js-cookie";

export function getToken() {
    let token = Cookies.get("token");
    if (token === undefined) return "";
    else return token;
}

export function getWxToken() {
    let token = Cookies.get("wxtoken");
    if (token === undefined) return "";
    else return token;
}

export function setToken(token) {
    return Cookies.set("token", token);
}

export function getLogin() {
    return Cookies.get("stuLogin");
}

export function setLogin(bol) {
    return Cookies.set("stuLogin", bol);
}
export function setOaPwd(pwd) {
    return Cookies.set("oapwd", pwd);
}
export function getOaPwd() {
    return Cookies.get("oapwd");
}
export function setPwd(pwd) {
    return Cookies.set("pwd", pwd);
}

export function getPwd() {
    return Cookies.get("pwd");
}

export function setSid(sid) {
    return Cookies.set("sid", sid);
}

export function getSid() {
    return Cookies.get("sid");
}

export function removeToken() {
    console.log('removeToken')
    return Cookies.remove("token",{ path: '' });
}

export function removeWxToken() {
    return Cookies.remove("wxtoken", {domain: ".sziit.cn"});
}
