import axios from 'axios'
import that from '../main'
import qs from 'qs'
import {getToken, removeToken} from './auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_APIHOST, // api的base_url
  timeout: 30000, // request timeout
})

service.interceptors.request.use(
  (config) => {
    // if (getToken()) {
    config.method === 'post' || config.method === 'put'
      ? (config.data = qs.stringify({ ...config.data }))
      : (config.params = { ...config.params })
    const token = getToken() // 获取Cookie
    config.headers = {
      token: token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    // if (token) {
    //   config.params = {'token': token} //后台接收的参数，后面我们将说明后台如何接收
    // }
    // }
    return config
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)
// respone interceptor
service.interceptors.response.use(
  (data) => {
    // 响应成功关闭loading
    // Do something with response data
    // console.log(typeof data.data.errno)
    console.log(data.data.errno + '--' + data.data.msg)

    if (data.data.errno === 0) {
      return data.data
    } else if (data.data.errno === 1) {
            removeToken()
        that.$router.push( {
            path: '/login',
            query: {
                redirect: that.$route.fullPath
            } // 将跳转的路由path作为参数，登录成功后跳转到该路由)
        })
      // that.$router.push('/login')
    } else {
      /* swal({
           type: "error",
           title: data.data.msg,
           buttonsStyling: false,
           confirmButtonClass: "btn btn-warning btn-fill",
           confirmButtonText: "好的"
         })*/
      // store.commit('setErr', data.data.msg)
    }
    return data.data
  },
  (error) => {
    /*swal({
      type: "error",
      title: `服务器出错`,
      buttonsStyling: false,
      confirmButtonClass: "btn btn-warning btn-fill",
      confirmButtonText: "好的"
    })*/
    return Promise.reject(error)
  }
)
export default service
