<template>
    <vue-typed-js :strings="['深圳信息','职业技术学院','Aiy计算机协会',title]"
                  :startDelay="1000"
                  :backSpeed="100"
                  :backDelay="1000"
    >
        <!--        <h5><span>{{title}}</span></h5>-->
        <h5 style="width:100%;overflow:hidden;white-space:nowrap;"><span class="typing"></span></h5>
    </vue-typed-js>

</template>

<script>
    export default {
        name: "Title",
        props: {
            title: {
                defalut: '深圳信息职业技术学院Aiy计算机协会',
                type: String
            }
        }
    }
</script>

<style scoped>
    h5 {
        margin: 10px;
    }

    h5 span {
        position: relative;
    }

    h5 span::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 10px;
        width: 100%;
        height: 20px;
        background: #ecf6ff;
    }
</style>
