import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Index from '../views/Index.vue'
import Index from '../views/Index'
import index2 from '../views/index2'
import User from '../views/User'
import UserInfo from '../views/UserInfo'
import ErrorPage from '../views/ErrorPage'
import UserEnroll from '../views/UserEnroll'
import Toupiao from '../views/Toupiao'
import Toupiao2 from '../views/Toupiao2'
import Login from '../views/login'
import {getToken, removeToken} from '@/util/auth'
import Result from '../views/result'
import MyTp from '../views/myTp'
import MyTp2 from '../views/myTp2'
import Touchangwulishi from '../views/Touchangwulishi.vue'
import Touhuizhang from '../views/Touhuizhang.vue'
import Toulishidanwei from '../views/Toulishidanwei.vue'
import NewPage from '../views/NewPage.vue'
import CooperativeGroupList from '../views/CooperativeGroupList'

import inviteRouter from './modules/invite'


import store from '../store'

Vue.use(VueRouter)


const routes = [
  ...inviteRouter,
  {
    path: '/myTp',
    name: 'myTp',
    component: MyTp,
    meta: { requireAuth: true }
  },
  {
    path: '/myTp2',
    name: 'myTp2',
    component: MyTp2,
    meta: { requireAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
    meta: { requireAuth: true }
  },
  {
    path: '/index2',
    name: 'index2',
    component: index2,
    meta: { requireAuth: false }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requireAuth: false },
    children: [
      {
        path: '/',
        name: 'index',
        component: index2,
        // redirect:'/subject',
        meta: { requireAuth: false }
      },
      {
        path: '/user',
        name: 'User',
        component: User,
        meta: { requireAuth: false }
      }
    ]
  },
  {
    path: '/userinfo',
    name: 'UserInfo',
    component: UserInfo,
    meta: { requireAuth: true }
  },
  {
    path: '/collageinfo/:tabName',
    name: 'CollageInfo',
    component: () => import('../views/CollageInfo.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/collageList',
    name: 'collageList',
    component: () => import('../views/CollageList.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/cooperativeGroup',
    name: 'cooperativeGroup',
    component: () => import('../views/CooperativeGroup.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/CooperativeGroupList/:groupName',
    name: 'CooperativeGroupList',
    component: CooperativeGroupList
  },
  {
    path: '/news/:id/:categoryName',
    name: 'news',
    component: () => import('../views/News.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/news2/:id/:categoryName',
    name: 'news',
    component: () => import('../views/News.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/newpage/:id/',
    name: 'newpage',
    component: () => import('../views/NewPage.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/newvideopage/:id/',
    name: 'newvideopage',
    component: () => import('../views/NewVideoPage.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/toupiaorukou',
    name: 'ToupiaoRuKou',
    component: () => import('../views/ToupiaoRuKou.vue'),
    meta: { requireAuth: false }
  },

  {
    path: '/toupiao/:id',
    name: 'toupiao',
    component: Touchangwulishi,
    meta: {requireAuth: true}
  },
  {
    path: '/touhuizhang',
    name: 'Touhuizhang',
    component: Touhuizhang,
    meta: { requireAuth: true }
  },
  {
    path: '/toulishidanwei',
    name: 'Toulishidanwei',
    component: Toulishidanwei,
    meta: { requireAuth: true }
  },
  {
    path: '/toupiao2',
    name: 'Toupiao2',
    component: Toupiao,
    meta: {requireAuth: true}
  },
  {
    path: '/toupiao2',
    name: 'Toupiao2',
    component: Toupiao2,
    meta: { requireAuth: true }
  },
  {
    path: '/errorpage',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: { requireAuth: true }
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requireAuth: true }
  },
  {
    path: '/userenroll',
    name: ' UserEnroll',
    component: UserEnroll,
    meta: { requireAuth: true }
  },
  {
    path: '/sso/:type',
    name: ' Sso',
    component: () => import('@/views/SSO.vue'),
    meta: { requireAuth: true }
  },
  { // 注册页面
    path: '/submitForm',
    name: 'SubmitForm',
    component: () => import('../views/submitForm.vue'),
    meta: { requireAuth: false }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { requireAuth: true }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  changeNavigationBar(to, store)
  if (to.matched.some(record => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    const token = getToken() // 获取Cookie
    if (token) {
      // 判断缓存里面是否有 token  //在登录的时候设置它的值
      next()
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})

// 更改导航栏状态
function changeNavigationBar(to, store) {
  // 默认的导航栏信息
  let status = {
    showBar: true,
    name: '导航栏'
  }
  //如果有在路由上设置页面导航栏信息，则使用页面设置的导航栏信息
  if (to.meta.navigationBarStatus) {
    status = {
      showBar: to.meta.navigationBarStatus.showBar,
      name: to.meta.navigationBarStatus.name
    }
  }
  store.commit('changeNavigationBar', status)
}

export default router
