<template>
    <div class="myContainer">
        <div class="top">
            <div class="toptop">
                <p>投票结果</p>
                <select id="mySelect" v-model="seVal" @change="selectChange">
                    <option v-for="(item, index) in pro" :value="item" :key="index">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="topbottom">
                <div>
                    <div class="d d1">应投票人数</div>
                    <div class="d d2">数量：{{ resData.yingtoupiao }}</div>
                    <div class="d d3 gl" @click="startShowMask(0)">详情</div>
                </div>

                <div>
                    <div class="d d1">已投票人数</div>
                    <div class="d d2">数量：{{ resData.yitoupiao }}</div>
                    <div class="d d3 gl" @click="startShowMask(1)">详情</div>
                </div>

                <div>
                    <div class="d d1">未投票人数</div>
                    <div class="d d2">数量：{{ resData.weitoupiao }}</div>
                    <div class="d d3 gl" @click="startShowMask(2)">详情</div>
                </div>
            </div>
        </div>
        <table border="1" style="font-size: 0.3rem;width: 100%">
            <caption>提名代表的推举</caption>
            <tr>
                <td>得票人</td>
                <td>同意票数</td>
                <td>不同意票数</td>
                <td>操作</td>
            </tr>
            <tr v-for="(item,index) in resData.timingdaibiaoList" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.zancheng}}</td>
                <td>{{item.buzancheng}}</td>
                <td @click="showDetail(item.id)"><p>详情</p></td>
            </tr>
        </table>
        <table border="1" style="font-size: 0.3rem;width: 100%">
            <caption>非提名代表的推举</caption>
            <tr>
                <td>得票人</td>
                <td>同意票数</td>
                <td>操作</td>
            </tr>
            <tr v-for="(item,index) in resData.feitimingdaibiaoList" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.zancheng}}</td>
                <td @click="showDetail(item.id)"><p>详情</p></td>
            </tr>
        </table>
        <table v-if="resData.feitimingdaibiaoerciList.length !=0" border="1" style="font-size: 0.3rem;width: 100%">
        <caption>非提名代表二次推举</caption>
        <tr>
          <td>得票人</td>
          <td>同意票数</td>
          <td>操作</td>
        </tr>
        <tr v-for="(item,index) in resData.feitimingdaibiaoerciList" :key="index">
          <td>{{item.name}}</td>
          <td>{{item.zancheng}}</td>
          <td @click="showDetail(item.id,2)"><p>详情</p></td>
        </tr>
      </table>

        <div class="mask" @click.stop="clickMask" v-show="showMask">
            <div class="maskContainer">
                <p>{{ maskContent.maskTitle }}</p>
                <md-scroll-view
                        ref="scrollView"
                        class="scrollView"
                        scrolling-y
                        :scrolling-x="false"
                        manual-init
                >
                    <div class="table">
                        <table border="1" style="font-size: 0.3rem">
                            <tr>
                                <td>学校名称</td>
                                <td>学校代码</td>
                                <td>姓名</td>
                            </tr>
                            <tr v-for="(item,index) in maskTable" :key="index">
                                <td>{{item.collage}}</td>
                                <td>{{item.collage_id}}</td>
                                <td>{{item.name}}</td>
                            </tr>
                        </table>
                    </div>
                    <!--          <tables :tableData="maskTable" :option="innerTableHeader"/>-->
                </md-scroll-view>
            </div>
        </div>
        <md-dialog
                :closable="false"
                v-model="detailDialog"
                mask-closable
        >
            <div slot="default">
                <table border="1" style="font-size: 0.3rem">
                    <tr>
                        <td>学校名称</td>
                        <td>学校代码</td>
                        <td>姓名</td>
                        <td>表决</td>
                    </tr>
                    <tr v-for="(item,index) in detailTable" :key="index">
                        <td>{{item.collage}}</td>
                        <td>{{item.collage_id}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.res}}</td>
                    </tr>
                </table>
            </div>
        </md-dialog>
        <md-action-bar :actions="actiondata"></md-action-bar>

    </div>
</template>

<script>
    import {InputItem, Field, Dialog, ActionBar, Button, Toast, Captcha, ScrollView} from "mand-mobile";

    export default {
        name: "input-item-demo",
        components: {
            [InputItem.name]: InputItem,
            [Field.name]: Field,
            [Captcha.name]: Captcha,
            [ScrollView.name]: ScrollView,
            [ActionBar.name]: ActionBar,
        },
        async mounted() {
            this.$API.user.getProvince().then((res) => {
                if (res.errno == 0) {
                    console.log(res.data);
                    this.pro = res.data;
                }
            });
        },
        data() {
            return {
                detailDialogbtns: [],
                // 详情计划
                detailDialog: false,
                // 详情内容
                detailTable: [],
                // 详情id
                detailId: '',
                actiondata: [
                    {
                        text: '返回',
                        onClick: () => {
                            this.$router.go(-1)
                        },
                    },
                    {
                        text: '导出实时数据',
                        onClick: () => {
                            window.location = 'http://kj.test.mdysz.cn/index.php?s=/index/Toupiaores/buildExcel'
                        },
                    },
                ],
                maskTable: [],
                resData: {
                    feitimingdaibiaoerciList: [],
                    downloadurl: "",
                    weitoupiao: "",
                    yingtoupiao: "",
                    yitoupiao: "",
                    yitoupiao_table: "",
                    yingtoupiao_table: "",
                    timingdaibiaoList: [],
                    weitoupiaoList: []
                },
                showMask: false,
                seVal: "0",
                pro: [],
                tableData: [
                    {
                        closedAccount: 0,
                        givePrice: 0,
                        noAccount: 0,
                        roomType: "XXX",
                        totalPrice: ''
                    },
                    {
                        closedAccount: 0,
                        givePrice: 0,
                        noAccount: 0,
                        roomType: "XXX",
                        totalPrice: ''
                    },
                    {
                        closedAccount: 0,
                        givePrice: 0,
                        noAccount: 0,
                        roomType: "XXX",
                        totalPrice: ''
                    },
                    {
                        closedAccount: 0,
                        givePrice: 0,
                        noAccount: 0,
                        roomType: "XXX",
                        totalPrice: ''
                    }
                ],
                tableHeader: {
                    column: [
                        {
                            label: '得票人',
                            prop: 'roomType'
                        },
                        {
                            label: '同意票数',
                            prop: 'closedAccount'
                        },
                        {
                            label: '不同意票数',
                            prop: 'noAccount'
                        },
                        {
                            label: '操作',
                            prop: 'totalPrice'
                        }
                    ]
                },
                innerTableHeader: {
                    column: [
                        {
                            label: '学校名称',
                            prop: 'roomType'
                        },
                        {
                            label: '学校代码',
                            prop: 'closedAccount'
                        },
                        {
                            label: '姓名',
                            prop: 'roomType'
                        }
                    ]
                },
                tmContent: {
                    title: "提名代表",
                    table: {
                        column: ["得票人", "同意票数", "不同意票数"],
                        cell: [
                            ["张三", 55, 10],
                            ["张三", 55, 10],
                            ["张三", 55, 10],
                            ["张三", 55, 10],
                            ["张三", 55, 10],
                        ],
                    },
                },
                maskContent: {
                    maskTitle: "应该投票学校",
                    maskTable: {
                        column: ["学校代码", "学校名称", "姓名"],
                        cell: [
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                            ["1002", "深圳信息职业技术学院", "李四"],
                        ],
                    },
                },
            };
        },
        methods: {
            // 加载详情
            showDetail(daibiao_ids,toupiaotype) {
                let daibiao_id = daibiao_ids
                this.$API.user.getDaibiaoDetail(daibiao_id,toupiaotype).then(res => {
                    this.detailTable = res.data
                    this.detailDialog = true
                })
            },
            getResData(value) {
                this.$API.user.getRes(value).then((res) => {
                    console.log(res);
                    this.resData = res.data;
                });
            },
            selectChange(e) {
                console.log(e.target.value);
                this.getResData(e.target.value);
            },
            hz() {
            },
            xz() {
                window.location = this.resData.downloadurl;
            },
            clickMask() {
                this.showMask = false;
            },
            startShowMask(type) {
                if (type == 0) {
                    this.maskContent.maskTitle = "应投票人数";
                    this.maskTable = this.resData.yingtoupiao_table
                } else if (type == 1) {
                    this.maskContent.maskTitle = "已投票人数";
                    this.maskTable = this.resData.yitoupiao_table
                } else if (type == 2) {
                    this.maskContent.maskTitle = "未投票人数";
                    this.maskTable = this.resData.weitoupiaoList
                }
                this.showMask = true;
                this.$refs.scrollView.init();
            },
        },
        watch: {
            seVal: {
                async handler(newVal, oldVal) {
                    console.log("newVal:", newVal);
                    let res = await this.$API.user.showRes({province: newVal});
                    console.log("showRes showRes res:", res);
                },
            },
        },
    };
</script>

<style lang="stylus" >
    .myContainer {
        padding: 40px;
    }

    .top {
        border: 1px solid rgba(0, 0, 0, 0.4);

        .toptop {
            font-size: 50px;
            display: flex;
            justify-content: space-between;
            color: black;
            padding: 10px;

            #mySelect {
                font-size: 20px;
            }
        }

        .topbottom {
            font-size: 30px;
            padding: 40px;

            > div:not(:first-child) {
                margin-top: 30px;
            }

            > div {
                display: flex;

                .d {
                    text-align: center;
                    flex: 4;
                }

                .d:not(:first-child) {
                    margin-left: 20px;
                }

                .d3 {
                    flex: 2;
                }
            }
        }
    }

    .bottom {
        border: 1px solid rgba(0, 0, 0, 0.4);
        padding-bottom: 200px;

        .bottomtop {
            font-size: 30px;
            color: black;
            padding: 10px;
            margin-top: 30px;
        }

        .bottombottom {
            font-size: 30px;
            padding: 40px;

            > div:not(:first-child) {
                margin-top: 30px;
            }

            > div {
                display: flex;

                .d {
                    text-align: center;
                    flex: 2;
                }

                .d:not(:first-child) {
                    margin-left: 20px;
                }

                .d2 {
                    flex: 4;
                }

                .d3 {
                    flex: 4;
                }
            }
        }
    }

    .allAction {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        .btn {
            flex: 1;
        }

        .btn2 {
            margin-left: 30px;
        }
    }

    .gl {
        color: blue;
    }

    .mask {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        height: 100vh;

        .maskContainer {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            p {
                font-size: 50px;
                color: #ffffff;
                text-align: center;
                margin-bottom: 30px;
            }
        }

        .scrollView {
            width: 80%;
            height: 60vh;
            // position: relative;
            margin: 0 auto;
            background-color: #fff;
        }

        .table {
            font-size: 30px;
            padding: 40px;

            > div:not(:first-child) {
                margin-top: 30px;
            }

            > div {
                display: flex;

                .d {
                    text-align: center;
                    flex: 3;
                }

                .d:not(:first-child) {
                    margin-left: 20px;
                }

                .d2 {
                    flex: 4;
                }

                .d3 {
                    flex: 3;
                }
            }
        }
    }
</style>
