<template>
	<div>
		<md-field name="school" class="rm">
			<md-input-item
				class="blod"
				ref="input4"
				title="学校名称"
				placeholder="点击输入学校名称"
				align="right"
				clearable
				v-model="school"
			>
			</md-input-item>
		</md-field>
		<md-field>
			<md-field-item
				class="blod"
				title="省份"
				:content="selectorValue"
				@click="showSelector"
				arrow
				solid
			/>
			<md-selector
				v-model="isSelectorShow"
				:data="province"
				max-height="320px"
				title="请选择省份"
				large-radius
				@choose="onSelectorChoose"
			>
			</md-selector>
		</md-field>
		<md-field :name="index" v-for="(item, index) in form" :key="index">
			<div>成员{{ index + 1 }}</div>
			<!-- v-model="form[index].province" -->
			<!-- <md-field>
				<md-field-item
					title="省份"
					:content="selectorValue"
					@click="showSelector"
					arrow
					solid
					v-model="form[index].province"
				/>
			</md-field> -->
			<!-- <md-selector
				v-model="isSelectorShow"
				default-value="0"
				:data="province[0]"
				max-height="320px"
				title=""
				large-radius
				@choose="onSelectorChoose"
			></md-selector> -->
			<!-- <md-selector
				v-model="isSelectorShow"
				default-value="2"
				:data="province[0]"
				max-height="320px"
				title="请选择省份"
				large-radius
				@choose="onSelectorChoose"
			></md-selector> -->
			<md-input-item
				class="blod"
				ref="input4"
				title="会员单位理事姓名"
				placeholder="会员单位理事姓名"
				align="right"
				v-model="form[index].name"
				clearable
			></md-input-item>
			<md-input-item
				class="blod"
				ref="input4"
				v-model="form[index].position"
				title="会员单位理事职务"
				placeholder="点击输入职务"
				align="right"
				clearable
			></md-input-item>
			<md-input-item
				class="blod"
				ref="input4"
				v-model="form[index].title"
				title="会员单位理事职称"
				placeholder="点击输入职称"
				align="right"
				clearable
			></md-input-item>
			<md-input-item
				class="blod"
				ref="input4"
				v-model="form[index].tel"
				title="联系手机"
				placeholder="联系手机"
				align="right"
				clearable
			></md-input-item>
			<md-field-item title="性别" solid class="blod">
				<md-radio name="1" v-model="form[index].gender" label="男" inline />
				<md-radio name="2" v-model="form[index].gender" label="女" inline />
			</md-field-item>
			<md-input-item
				class="blod"
				ref="input4"
				v-model="form[index].wechat"
				title="微信号"
				placeholder="点击输入微信号"
				align="right"
				clearable
			>
			</md-input-item>
			<md-input-item
				class="blod"
				title="邮箱"
				v-model="form[index].email"
				placeholder="点击输入邮箱"
				align="right"
				clearable
			>
			</md-input-item>
		</md-field>
		<md-field>
			<div class="add">
				<span>新增成员</span>
				<md-button type="warning" size="small" inline @click="addUser">+</md-button>
			</div>
		</md-field>
	</div>
</template>

<script>
import {
	InputItem,
	Field,
	Radio,
	FieldItem,
	TextareaItem,
	Button,
	Selector,
	ActionBar,
} from "mand-mobile"
export default {
	name: "MyFile",
	components: {
		[InputItem.name]: InputItem,
		[Field.name]: Field,
		[Radio.name]: Radio,
		[Field.name]: Field,
		[FieldItem.name]: FieldItem,
		[Radio.name]: Radio,
		[TextareaItem.name]: TextareaItem,
		[Selector.name]: Selector,
		[ActionBar.name]: ActionBar,
	},
	name: "UserEnroll",
	data() {
		return {
			school: "",
			sex: "1",
			value: "",
			counter: [],
			form: [],
			isSelectorShow: false,
			selectorValue: "",
			province: [
				{
					value: "1",
					text: "北京市",
				},
				{
					value: "2",
					text: "天津市",
				},
				{
					value: "3",
					text: "上海市",
				},
				{
					value: "4",
					text: "重庆市",
				},
				{
					value: "5",
					text: "广东省",
				},
				{
					value: "6",
					text: "河北省",
				},
				{
					value: "7",
					text: "河南省",
				},
				{
					value: "8",
					text: "云南省",
				},
				{
					value: "9",
					text: "辽宁省",
				},
				{
					value: "10",
					text: "黑龙江省",
				},
				{
					value: "11",
					text: "湖南省",
				},
				{
					value: "12",
					text: "安徽省",
				},
				{
					value: "13",
					text: "山东省",
				},
				{
					value: "14",
					text: "江苏省",
				},
				{
					value: "15",
					text: "浙江省",
				},
				{
					value: "16",
					text: "江西省",
				},
				{
					value: "17",
					text: "湖北省",
				},
				{
					value: "18",
					text: "甘肃省",
				},
				{
					value: "19",
					text: "山西省",
				},
				{
					value: "20",
					text: "陕西省",
				},
				{
					value: "21",
					text: "吉林省",
				},
				{
					value: "22",
					text: "福建省",
				},
				{
					value: "23",
					text: "贵州省",
				},
				{
					value: "24",
					text: "青海省",
				},
				{
					value: "25",
					text: "四川省",
				},
				{
					value: "26",
					text: "海南省",
				},
			],
			selectorValue: "",
		}
	},
	created() {},
	mounted() {
		this.addUser()
	},
	methods: {
		format(name, curValue, curPos) {
			return {
				value: curValue.replace(/\d/g, ""),
				range: curPos,
			}
		},
		// 添加成员
		addUser() {
			const tmp = {
				province: "",
				name: "",
				position: "",
				title: "",
				tel: "",
				gender: "",
				wechat: "",
				email: "",
			}
			this.$set(this.form, this.form.length, tmp)
		},
		add() {
			console.log("1")
			this.counter.push({})
		},
		goback() {
			this.$router.go(-1)
		},
		showSelector() {
			this.isSelectorShow = true
		},
		onSelectorChoose({ text }) {
			this.selectorValue = text
		},
		submmit_info() {
			console.log(this.form)
			let users = []
			this.form.map((value, index) => {
				console.log(value)
				users.push({
					mobile: value.tel,
					gender: value.gender,
					name: value.name,
					zhiwei: value.position,
					zhicheng: value.title,
					user_email: value.email,
					wechat: value.wechat,
				})
			})

			let userInfo = {
				collage: this.school,
				province: this.selectorValue,
				user: users,
			}
			// for (let user of this.form) {
			//   userInfo.user.push(user);
			// }
			console.log(userInfo)

			this.$API.register.registerUser(userInfo)
		},
	},
}
</script>

<style lang="stylus" scoped>
.blod{
	font-weight:bold
}
span {
  font-size: 34px;
}

.add {
  float: right;
}
.rm{

	padding-bottom :0
}

.demo2-footer {
  display: flex;
  justify-content: space-between;
  color: #999;
  font-size: 24px;
}

.md-example-section {
  display: flex;
  margin-top: 200px;

  .md-button {
    width: 300px;
    margin-left: 50px;
  }
}
</style>
