import axios from '../util/request'

export default {
    // 签到
    ssoChunshu() {
        return axios.get('/index/user/ssochunshu')
    },


}
