import axios from '../util/request'

export default {
    // 签到
    qiandao(id) {
        return axios.post('index/qiandao/qiandao', {id})
    },
    // 检查签到情况
    checkQiandao(){
        return axios.get('index/qiandao/checkQiandao', )
    }

}
