const inviteRouter = [
    {
        path: '/inviteAudit',
        name: 'InviteAudit',
        component: () => import('@/views/invite/inviteAudit'),
        meta: { requireAuth: true }
    },
    {
        path: '/inviteHome',
        name: 'inviteHome',
        component: () => import('@/views/invite/inviteHome'),
        meta: { requireAuth: true }
    },
    {
        path: '/inviteLink',
        name: 'InviteLink',
        component: () => import('@/views/invite/inviteLink'),
        meta: { requireAuth: true }
    },
    {
        path: '/inviteDetail',
        name: 'InviteDetail',
        component: () => import('@/views/invite/inviteDetail'),
        meta: { requireAuth: true }
    }
]

export default inviteRouter
