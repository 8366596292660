import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "normalize.css";
import "./assets/common.css";
import API from './api'
import Title from "./components/Title";
import {Toast, ActionBar,} from 'mand-mobile'
Vue.prototype.$toast = Toast
Vue.component(ActionBar.name, ActionBar)
import 'mand-mobile/lib/mand-mobile.css'
// import './assets/mytheme.css'
import VueMasonry from 'vue-masonry-css'
import commonMotheds from './components/CommonMotheds'
import WechatDialog from './components/WechatDialog.vue'
import { ImageViewer } from 'mand-mobile'
import { setToken } from '@/util/auth.js'
import navigationBar from "@/components/navigationBar";


/* 文字复制到剪切板 */
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.component('NavigationBar',navigationBar);
Vue.use(VueMasonry);
Vue.component('Title', Title)
Vue.component('WechatDialog', WechatDialog)
Vue.component(ImageViewer.name, ImageViewer)

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./assets/icon', false, /\.svg$/)
requireAll(req)

/* adtest环境下免登录 */
if(process.env.NODE_ENV === 'adtest'   ) {
    setToken(process.env.VUE_APP_TOKEN)
    API.user.getUserInfo().then(data => {
       store.commit('changeUserInfo', data.data)
    })
}

Vue.mixin(commonMotheds) //混入公共方法

Vue.config.productionTip = false
Vue.prototype.$API = API //请求的API

let that = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default that
