import axios from '../util/request'

export default {
  hasUser() {
    return axios.get('/score/Services/hasUser')
  },

  // 获取验证码
  getVerificationCode(data) {
    return axios.post('/index/login/sendLoginSmsCode', data)
  },

  // 开始登录，获取用户信息
  doLogin(data) {
    return axios.post('/index/login/doLogin', data)
  },

  // 获取用户信息
  getUserInfo() {
    return axios.get('/index/user/getUserInfo')
  },

  // 以下是用户投票页面的接口
  // 加载投票数据
  initData(toupiaotype) {
    return axios.post('/index/toupiao/initData', {toupiaotype})
  },

  // 投票
  toupiao(data) {
    return axios.post('index/user/toupiao', data)
  },
  // 投票
  toupiao2(data) {
    return axios.post('index/user/toupiao2', data)
  },
  // 投票
  getRes(province) {
    return axios.post('index/Toupiaores/showRes', { province })
  },
  // 获取省份
  getProvince() {
    return axios.get('index/Toupiaores/getProvince')
  },

  // 统计接口
  showRes(data) {
    return axios.post('/index/Toupiaores/showRes', data)
  },
  // 获取自己的投票结果
  showMytoupiao(toupiaotype) {
    return axios.post('index/user/showMytoupiao',{toupiaotype})
  },
  // 获取代表结果
  getDaibiaoDetail(daibiao_id,toupiaotype) {
    return axios.post('index/toupiaores/daibiaoDetail', {daibiao_id,toupiaotype})
  },
  // 能否投票
  cantou(toupiaotype) {
    return axios.post('index/Toupiao/cantou',{toupiaotype})
  },
  // 获取学校信息
  selectCollage() {
    return axios.get('manage/userinfo/selectUserCollage')
  },
  // 修改用户信息
  changeUserInfo(data) {
    return axios.post('index/userinfo/moduser', data)
  }
}
