import axios from '../util/request'

export default {
    // 签到
    getCategoryPostLists(category_id) {
        return axios.get('https://kjcmf.mdysz.cn/api.php/portal/lists/getCategoryPostLists', {params:{category_id,'field':'id,published_time,post_title,thumbnail,post_hits','order':'-published_time','limit':99999}})
    },
    articles(id){
        return axios.get('https://kjcmf.mdysz.cn/api.php/portal/articles/'+id)
    },

}
